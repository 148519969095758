import λ from '~/utils/invoker';
import { getId } from '#/object-id';
import ListMixin from '~/mixins/list';
import { routeNames } from '~/router';
import PermissionMixin from '~/mixins/permission';
import PaginatedTable from '~/components/paginated-table.vue';
import ConfirmDialog from '~/components/confirm-dialog.vue';
import GoBackWrapper from '~/components/go-back-wrapper.vue';
import { roles } from '#/role';
import { mdiTrashCanOutline, mdiPlusCircleOutline, mdiMagnify } from '@mdi/js';
const usersλ = λ.namespaced('users');
export default {
    name: 'user-list',
    mixins: [ListMixin('users'), PermissionMixin],
    components: {
        ConfirmDialog,
        GoBackWrapper,
        PaginatedTable,
    },
    data() {
        return {
            mdiMagnify,
            mdiTrashCanOutline,
            mdiPlusCircleOutline,
            roles,
            query: {
                sortBy: 'lastName',
                sortDesc: false,
                page: 1,
                itemsPerPage: 10,
                filters: {
                    search: null,
                    role: null,
                },
            },
        };
    },
    computed: {
        allUsers: usersλ.state('all'),
        totalCount: usersλ.state('totalCount'),
        headers() {
            return [
                { text: this.$t('First name'), value: 'firstName' },
                { text: this.$t('Last name'), value: 'lastName' },
                { text: this.$t('E-mail'), value: 'email' },
                { text: this.$t('Role'), value: 'role' },
                { text: this.$t('userId'), value: 'userId' },
                { text: this.$t('LuxTrustSN20'), value: 'LuxTrustSN20' },
                ...(this.canDeleteUsers ? [{ text: this.$t('Delete'), sortable: false, }] : []),
            ];
        },
    },
    methods: {
        remove: usersλ.action('remove'),
        openItem(item) {
            if (this.canReadUsers) {
                this.$router.push({
                    name: routeNames.users.edit,
                    params: { id: getId(item) }
                });
            }
        },
        addNewItem() {
            this.$router.push({
                name: routeNames.users.create,
            });
        },
        removeUser(user) {
            this.$refs.deleteDialog
                .open()
                .then(async (doDelete) => {
                if (doDelete) {
                    await this.remove(user);
                }
            });
        },
    },
};
